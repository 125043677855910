import React, { useState, useEffect } from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import Container from '../../components/container'
import Link from '../../components/link'
import { graphql, Link as GatsbyLink } from 'gatsby'
import DemoForm from '../../components/demo_form'
import Box from '../../components/box'
import Button from '../../components/button'
import Icon from '../../components/icon'
import VideoModal from '../../components/video_modal'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { styled } from '../../styles/stitches.config'

const StyledTout = styled('div', {
  width: 21,
  height: 4,
  borderColor: 'transparent',
  backgroundColor: '$silver',
  variants: {
    active: {
      true: {
        borderColor: '$white',
        backgroundColor: 'transparent'
      }
    }
  }
})

function getFeaturedImage (img) {
  const images = withArtDirection(getImage(img.asset), [
    {
      media: '(max-width: 767px)',
      image: getImage(img.assetSmall)
    }
  ])
  return <GatsbyImage image={images} className='absolute inset-0' />
}

const CustomerStoriesPage = ({ data }) => {
  
  const content = data.contentfulPage
  const featured = data.allContentfulStory.nodes

  const [slideIndex, setSlideIndex] = useState(0)
  
  useEffect(() => {
    setTimeout(() =>
      setSlideIndex((prevIndex) =>
        prevIndex === featured.length - 1 ? 0 : prevIndex + 1
      ),
      8000
    )
    return () => {}
  }, [slideIndex, featured])
  
  return (
    <Layout activeNav={true}>
      <Seo
        title={content.seoTitle || content.title}
        description={content.metaDescription || content.subtitle}
      />
      <Box
        css={{
          height: 540,
          '@sm': {
            height: '70vh',
            minHeight: 580
          }
        }}
        className='bg-black text-white relative'
      >
        {featured.map((feature, featureIndex) =>
          <div
            key={`featured_story_${feature.slug}`}
            className='absolute inset-0'
            style={slideIndex !== featureIndex ? { display: 'none' } : null}
          >
            {getFeaturedImage(feature.featuredBackgroundImage)}
            <Box
              css={{
                paddingTop: 'calc(var(--header-height) + $space$3)',
                '@sm': {
                  paddingTop: 0
                }
              }}
              className='relative sm:flex flex-col justify-center h-full text-center sm:text-left'
            >
              <Container>
                <div className='sm:text-md opacity-60 uppercase font-semi-bold'>
                  Featured Customer
                </div>
                <h1 className='mt-0 sm:text-3xl lg:text-4vw mb-1/4em'>
                  {feature.name}
                </h1>
                <div className='sm:text-md mx-auto sm:ml-0' style={{ maxWidth: '40em' }}>
                  <MDXRenderer children={feature.featuredText.childMdx.body} />
                </div>
                <div className='flex items-center justify-center sm:justify-start mt-3'>
                  <Button
                    variant='light'
                    children='Read Story'
                    to={`/customer_stories/${feature.slug}`}
                  />
                  {feature.video?.videoId &&
                    <VideoModal videoId={feature.video.videoId}>
                      <Link className='ml-3 text-md font-semi-bold'>
                        <Icon
                          name='play'
                          className='mr-1/2em align-middle'
                          style={{ width: '2em', height: '2em' }}
                        />
                        Watch Video
                      </Link>
                    </VideoModal>
                  }
                </div>
              </Container>
            </Box>
          </div>
        )}
        <div className='flex justify-center absolute bottom-0 left-0 right-0 mb-3 sm:mb-4'>
          {featured.map((_, toutIndex) =>
            <StyledTout
              key={`tout_${toutIndex}`}
              className='mx-1 border rounded-sm z-1'
              active={slideIndex === toutIndex} />
          )}
        </div>
      </Box>
      <div className='Section Section--condensed Section--stripe'>
        <Container size='lg'>
          <h2 className='text-xl sm:text-2xl font-light text-center mt-0 mb-4'>
            {content.subtitle}
          </h2>
          {/*
          <div className='flex justify-end mb-3 text-base'>
            <Link
              children='Sort by: All'
              arrowDirection='down'
            />
            <ul className='list-reset'>
              <li>
                <Link
                  variant='muted'
                  children='All'
                />
              </li>
              <li>
                <Link
                  variant='muted'
                  children='Online'
                />
              </li>
              <li>
                <Link
                  variant='muted'
                  children='Omnichannel'
                />
              </li>
              <li>
                <Link
                  variant='muted'
                  children='In-store'
                />
              </li>
            </ul>
          </div>
          */}
          <div className='grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:gap-4'>
            {content.items.map(story =>
              <GatsbyLink
                key={`story_link_${story.slug}`}
                to={`/customer_stories/${story.slug}`}
                className='flex flex-col bg-white rounded-md p-3 hover:shadow-lg transition-shadow border border-darken1 group'
              >
                <div>
                  <div className='text-gray text-base font-semi-bold'>
                    {story.storyType}
                  </div>
                  <h3 className='text-md mt-1'>
                    {story.name}
                  </h3>
                  <div className='my-3'>
                    <GatsbyImage
                      image={story.avatar.gatsbyImageData}
                      alt=''
                      className='rounded-full overflow-hidden'
                      imgClassName='rounded-full'
                    />
                  </div>
                  <Box
                    css={{ '& b': { fontWeight: '$semi-bold' } }}
                    className='leading-relaxed text-md'>
                    {story.blurb}
                  </Box>
                </div>
                <div className='mt-auto pt-4'>
                  <Link
                    variant='muted'
                    arrowDirection='right'
                    className='group-hover:opacity-100 uppercase font-semi-bold tracking-wide'
                    children='Read Story'
                  />
                </div>
              </GatsbyLink>
            )}
          </div>
        </Container>
      </div>
      <div className='Section'>
        <Container>
          <DemoForm />
        </Container>
      </div>
    </Layout>
  )
}

export default CustomerStoriesPage

export const pageQuery = graphql`
  query {
    contentfulPage(url: {eq: "customer_stories"}) {
      title
      seoTitle
      metaDescription
      subtitle
      items {
        ... on ContentfulStory {
          name
          blurb
          slug
          storyType
          avatar {
            gatsbyImageData(width: 100, placeholder: BLURRED)
          }
        }
      }
    }
    allContentfulStory(
      filter: {featuredPosition: {ne: null}}
      sort: {fields: featuredPosition}
    ) {
      nodes {
        name
        slug
        featuredBackgroundImage {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
          assetSmall {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        featuredPosition
        featuredText {
          childMdx {
            body
          }
        }
        video {
          videoId
        }
      }
    }
  }
`
